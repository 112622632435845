<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-6"><p class="text-start m-0 pt-2 pb-2"><router-link :to="{name:'Home'}">&copy; Optimus Online</router-link></p></div>
        <div class="col-6"><p class="text-end m-0 pt-2 pb-2">Powered by: <a target="_blank" href="https://www.facebook.com/groups/338654837340138">Bits & Bytes</a></p></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.footer{
  background-color: #444444;
  color: #ffffff;
}
.footer p a{
  text-decoration: none;
  font-weight: bold;
  color: #ffffff;
}
</style>