<template>
  <nav class="navbar navbar-dark bg-dark navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <a class="navbar-brand text-uppercase logo" href="#" style="color: #FF6600; text-shadow: 1px 1px 2px #ffffff ">Optimus Online</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <li class="nav-item"><router-link class="nav-link active" to="/">Home</router-link></li>
          <li class="nav-item"><router-link class="nav-link" to="/about">About</router-link></li>
          <li class="nav-item"><router-link class="nav-link" :to="{name:'Packages'}">Packages</router-link></li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Entertainment
            </a>
            <ul class="dropdown-menu">
<!--              <li><a class="dropdown-item" href="#">Action</a></li>-->
<!--              <li><a class="dropdown-item" href="#">Another action</a></li>-->
<!--              <li><a class="dropdown-item" href="#">Something else here</a></li>-->


              <li><a class="dropdown-item" target="_blank" href="http://10.242.242.10/">Partner FTP 1</a></li>
              <li><a class="dropdown-item" target="_blank" href="http://10.16.100.244">Partner FTP 2</a></li>
              <li><a class="dropdown-item" target="_blank" href="http://cdn.dflix.live/">Partner FTP 3</a></li>
              <li><a class="dropdown-item" target="_blank" href="http://172.16.50.7/">Partner FTP 4</a></li>
              <li><a class="dropdown-item" target="_blank" href="http://40.40.40.40/">Live TV-1</a></li>
              <li><a class="dropdown-item" target="_blank" href="http://amrbd.com/">Live TV-2</a></li>
              <li><a class="dropdown-item" target="_blank" href="http://bdiptv.net/">Live TV-3</a></li>
            </ul>
          </li>
          <li class="nav-item"><router-link class="nav-link" :to="{name:'Contact'}">Contact</router-link></li>
        </ul>
      </div>
    </div>
  </nav>


<!--<div class="menu" id="menu">-->
<!--  <div class="container-fluid">-->
<!--    <div class="row">-->
<!--      <div class="col-12">-->
<!--        <div class="logo text-uppercase" style="color: #FF6600; text-shadow: 1px 1px 2px #ffffff ">Optimus Online</div>-->
<!--        <nav class="">-->
<!--          <ul>-->
<!--            <li><router-link to="/">Home</router-link></li>-->
<!--            <li><router-link to="/about">About</router-link></li>-->
<!--            <li><router-link :to="{name:'Packages'}">Packages</router-link></li>-->
<!--&lt;!&ndash;            <li><router-link to="#tv">Live TV</router-link>&ndash;&gt;-->
<!--&lt;!&ndash;              <ul>&ndash;&gt;-->
<!--&lt;!&ndash;                <li><a target="_blank" href="http://40.40.40.40/">Live TV-1</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;                <li><a target="_blank" href="http://172.16.50.2/">Live TV-1</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;                <li><a target="_blank" href="http://bdiptv.net/">Live TV-2</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;                <li><a target="_blank" href="http://bciptv.net/">Live TV-3</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;                <li><a target="_blank" href="http://10.16.100.244/livetv.php#top">Live TV-4</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;              </ul>&ndash;&gt;-->
<!--&lt;!&ndash;            </li>&ndash;&gt;-->

<!--            <li><a href="#ftp">Entertainment</a>-->
<!--              <ul>-->
<!--                <li><a target="_blank" href="http://10.242.242.10/">Partner FTP 1</a></li>-->
<!--                <li><a target="_blank" href="http://10.16.100.244">Partner FTP 2</a></li>-->
<!--                <li><a target="_blank" href="http://cdn.dflix.live/">Partner FTP 3</a></li>-->
<!--                <li><a target="_blank" href="http://172.16.50.7/">Partner FTP 4</a></li>-->
<!--                <li><a target="_blank" href="http://40.40.40.40/">Live TV-1</a></li>-->
<!--                <li><a target="_blank" href="http://amrbd.com/">Live TV-2</a></li>-->
<!--                <li><a target="_blank" href="http://bdiptv.net/">Live TV-3</a></li>-->
<!--              </ul>-->
<!--            </li>-->
<!--            <li><router-link :to="{name:'Contact'}">Contact</router-link></li>-->
<!--          </ul>-->
<!--        </nav>-->
<!--        <div class="menu-toggle" @click="menuToggle">-->
<!--          <i class="fa fa-bars" v-if="show" aria-hidden="true"></i>-->
<!--          <i class="fa fa-times" v-if="!show" aria-hidden="true"></i>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
</template>

<script>
export default {
    data() {
        return {
            show:true
        }
    },
    methods: {
        menuToggle(){
            document.querySelector('nav').classList.toggle("active");
            this.show = ! this.show;
        }
    },
}
</script>

<style scoped>
.menu{
    padding: 0 20px;
    background: #262626;
    box-sizing: border-box;
}

.menu .logo{
    color: #fff;
    height: 50px;
    line-height: 50px;
    font-size: 24px;
    float: left;
    font-weight: bold;
}

.menu nav{
    float: right;
}

.menu nav ul{
    margin: 0;
    padding: 0;
    display: flex;
}

.menu nav ul li{
    list-style: none;
    position: relative;
}

.menu nav ul li a{
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    color: #fff;
    text-decoration: none;
    display: block;
}

.menu nav ul li a:hover, .menu nav ul li a.router-link-exact-active{
    color: #fff;
    /*background: #ffc107;*/
    background: #2196f3;
}

.menu nav ul li ul {
  background-color: #2196f3;
  position: absolute;
  left: -4000px;
  display: block;
  width: 200px;
  float: none;
  z-index: 10;
  text-align: left;
}
.menu nav ul li:hover ul{
  left: 0px;
}

.menu nav ul li ul li{
  border-bottom: 1px solid #aaaaaa;
}

.menu nav ul li ul li:last-child{
  border-bottom: none;
}

.menu nav ul li ul li a:hover{
  color: #fff;
  /*background: #ffc107;*/
  background: #262626;
}

.menu-toggle{
    color: #fff;
    float: right;
    line-height: 50px;
    font-size: 24px;
    cursor: pointer;
    display: none;
}

@media (max-width: 991px){
    .menu{
        padding: 0 20px;
    }
    .menu-toggle{
      display: block;
    }
    .menu nav{
        position: absolute;
        width: 300px;
        height: calc(100vh-50px);
        background: #333;
        top:50px;
        left: -100%;
        transition: 0.5s;
      z-index: 9999;
    }

    .menu nav.active{
        left: 0;
    }

    .menu nav ul{
        display: block;
        text-align: left;
    }

    .menu nav ul li a{
        border-bottom: 1px solid rgba(0, 0, 0, 0.2)
    }

  .menu nav ul li:hover ul{
    left: 100%;
  }
}
</style>
