<template>
  <div class="big-footer bg-secondary">
    <div class="container-fluid">
      <div class="row">
        <div class="d-flex flex-lg-row flex-column p-2 bd-highlight">
          <div class="p-4 w-100 bg-light me-lg-2 rounded optimus-orange-box-shadow">
            <h4 class="fw-bold big-f-title-shadow" style="color: #0071BB">Optimus Online Internet</h4>
            <div class="text-center">
              <img alt="Vue logo" src="@/assets/images/opt-logo.png">
            </div>
          </div>
          <div class="p-4 w-100 bg-primary me-lg-2 rounded optimus-orange-box-shadow" style=""><h4 class="fw-bold big-f-title-shadow">Office Address </h4>
            <ul class="address">
              <li><p class="ps-3 fw-bold text-light" >House No.4/9, Block-F, Lalmatia, <br> Mohammadpur, Dhaka-1207</p></li>
              <li><p class="ps-3 fw-bold text-light"> House No.29, Block-E, Zakir Hossain Road <br> Lalmatia, Mohammadpur, Dhaka-1207</p></li>
            </ul>
          </div>
          <div class="p-4 w-100 bg-success rounded optimus-orange-box-shadow">
            <h4 class="fw-bold big-f-title-shadow">Emergency Contact</h4>
            <ul class="contact">
              <li><p class="ps-3 fw-bold text-light">Helpline : 09639-199944 01845-599944, 01886-933881</p></li>
              <li><p class="ps-3 fw-bold text-light">Marketing : 01316-222599</p></li>
              <li><p class="ps-3 fw-bold text-light">Billing : 01845-599944 (bKash & Nagad Marchant)</p></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BigFooter"
}
</script>

<style scoped>
.big-footer{
  /*padding-top: 30px;*/
  /*padding-bottom: 10px;*/
  text-align: left;
  /*background-color: #198754;*/
}

.big-f-title-shadow{
  color: antiquewhite;
  text-shadow: 1px 1px 1px #FF6600;
}

.big-footer p{
  color: #dddddd;
}
.address li{
  list-style-type: circle;
  list-style-position: initial;
}

.contact li{
  list-style-type: circle;
  list-style-position: initial;
}
.optimus-orange-box-shadow{
  box-shadow: 1px 1px 2px 2px #aaaaaa;
}
</style>
