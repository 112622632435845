<template>
  <div class="regular-package pb-5 pt-3">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12 mb-3 package" v-for="(item,key ) in services" :key="key">
        <div class="card p-2">
          <div class="card-header bg-light"><h4 class="text-dark fw-bold mb-0">{{ item.name }}</h4></div>
          <div class="card-body p-0 table-responsive">
            <p class="fw-normal p-2">{{ item.description }}</p>
          </div>
<!--          <div class="card-footer fw-bold ps-2 pe-2"><button class="btn btn-info btn-sm form-control fw-bold">ORDER NOW</button></div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegularPackage",
  data(){
    return {
      services: [
        {name:'Home Broadband Internet', description:'Optimus Online is providing one of the fastest broadband internet and network solutions throughout Dhaka, Trusted and Reliable to all kind of home and corporate users.'},
        {name:'Corporate/SME Internet', description:'We Offer dedicated internet connection with various network solutions and ensure network stability for our Corporate and SME user. Along with a support team for 24/7 response.'},
        {name:'Network Solutions', description:'Optimus Online provides both LAN & WAN networks solutions. With the help of our expert network engineers, we ensure the most efficient Network solutions for our clients.'},
        {name:'CCTV System Solutions', description:'Optimus Online provides high quality CCTV and IP surveillance solutions to residential, commercial, and industrial customers in Dhaka.'},
        {name:'Dedicated Internet', description:'Optimus Online Provides dedicated high-speed internet connectivity with multiple upstream support and multiple backup links to ensure 99.9% uptime.'},
        {name:'Real IP With IPV-4/6', description:'Optimus Online Provides Real IP to our valuable Clients for accurate routing and seamless connectivity in the digital realm'},
      ]
    }
  },
}
</script>

<style scoped>
.package:hover{
  transform: scale(1.1);
  transition: 0.3s;
  z-index: 10;
}
</style>
