<template>
  <div class="slider" id="slider">
<!--    <agile  :dots="true" :infinite="true" :autoplay-speed="3000" :options="myOptions">-->
<!--      <div class="slide">-->
<!--        <h3>slide 1</h3>-->
<!--      </div>-->

<!--      <div class="slide">-->
<!--        <h3>slide 2</h3>-->
<!--      </div>-->

<!--      <div class="slide">-->
<!--        <h3>slide 3</h3>-->
<!--      </div>-->

<!--      <div class="slide">-->
<!--        <h3>slide 4</h3>-->
<!--      </div>-->

<!--      <div class="slide">-->
<!--        <h3>slide 5</h3>-->
<!--      </div>-->
<!--    </agile>-->

<!--    <agile>-->
<!--      <div class="slide" v-for="n in 6" :key="n" :class="`slide&#45;&#45;${n}`">-->
<!--        <h3>{{ n }}</h3>-->
<!--      </div>-->
<!--      <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>-->
<!--      <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>-->
<!--    </agile>-->

    <agile :initial-slide="0" :options="myOptions" :speed="2000">
<!--      <img class="slide" src="../../assets/slider/free-connection.jpg"/>-->
      <img class="slide" src="../../assets/slider/free.jpg"/>
      <img class="slide" src="../../assets/slider/cover.jpg"/>
      <img class="slide" src="../../assets/slider/newBkash.gif"/>
      <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
      <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>
    </agile>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile'
export default {
  name: "Slider",
  components:{
    agile: VueAgile
  },
  data()
  {
    return {
      myOptions: {
        navButtons: true,
        autoplay:true,
        autoplaySpeed:5000,
        pauseOnHover:true,
        fade:true,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              dots: false
            }
          },

          {
            breakpoint: 900,
            settings: {
              navButtons: true,
              dots: true,
              infinite: true
            }
          }
        ]
      }
    }
  },
  mounted() {

  }
}
</script>

<style>
#slider {
  /*display: flex;*/
  font-family: "Lato", sans-serif;
  font-weight: 300;
  margin: 0 auto;
  /*max-width: 900px;*/
  /*padding: 30px;*/
}
/*.agile {*/
/*  width: 100%;*/
/*}*/
/*.agile__actions {*/
/*  margin-top: 20px;*/
/*}*/
/*.agile__nav-button {*/
/*  background: transparent;*/
/*  border: none;*/
/*  color: #ccc;*/
/*  cursor: pointer;*/
/*  font-size: 24px;*/
/*  padding: 20px;*/
/*  transition-duration: 0.3s;*/
/*}*/
/*.agile__nav-button:hover {*/
/*  color: #888;*/
/*}*/
/*.agile__dot {*/
/*  margin: 0 10px;*/
/*}*/
/*.agile__dot button {*/
/*  background-color: #eee;*/
/*  border: none;*/
/*  border-radius: 50%;*/
/*  cursor: pointer;*/
/*  display: block;*/
/*  height: 10px;*/
/*  font-size: 0;*/
/*  line-height: 0;*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  transition-duration: 0.3s;*/
/*  width: 10px;*/
/*}*/
/*.agile__dot--current button, .agile__dot:hover button {*/
/*  background-color: #888;*/
/*}*/

/*.slide {*/
/*  align-items: center;*/
/*  color: #fff;*/
/*  display: flex;*/
/*  height: 300px;*/
/*  justify-content: center;*/
/*}*/
/*.slide h3 {*/
/*  font-size: 32px;*/
/*  font-weight: 300;*/
/*}*/

/*.slide--1 {*/
/*  background-color: #f1c40f;*/
/*}*/

/*.slide--2 {*/
/*  background-color: #e67e22;*/
/*}*/

/*.slide--3 {*/
/*  background-color: #e74c3c;*/
/*}*/

/*.slide--4 {*/
/*  background-color: #9b59b6;*/
/*}*/

/*.slide--5 {*/
/*  background-color: #3498db;*/
/*}*/

/*.slide--6 {*/
/*  background-color: #2ecc71;*/
/*}*/

.agile__nav-button {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  transition-duration: 0.3s;
  width: 80px;
}
.agile__nav-button:hover {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}
.agile__nav-button--prev {
  left: 0;
}
.agile__nav-button--next {
  right: 0;
}
.agile__dots {
  bottom: 10px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}
.agile__dot {
  margin: 0 10px;
}
.agile__dot button {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}
.agile__dot--current button, .agile__dot:hover button {
  background-color: #fff;
}

.slide {
  display: block;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}
</style>
