<template>
  <div id="app">
    <Menu></Menu>
    <router-view/>
    <BigFooter/>
    <Footer/>
  </div>
</template>

<script>
import Menu from './components/includes/Menu.vue';
import BigFooter from "@/components/includes/BigFooter";
import Footer from "@/components/includes/Footer";

export default { 
name: 'App',
components: {
  Menu,BigFooter, Footer
},
data() {
  return {
    
  }
},
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
