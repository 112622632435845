<template>
  <div class="home">
    <Slider></Slider>
    <div class="bg-light">
      <div class="container pt-3">
        <h1 class="text-uppercase fw-bold optimus-orange-text" style="text-shadow: 2px 2px 2px #444444 ">Our Core Services</h1>
        <Services/>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Slider from "@/components/home/Slider";
import Services from "@/components/services/Services.vue";

export default {
  name: 'Home',
  components: {
    Slider, Services
  },
  data() {
    return {
      title:'Home',
    }
  },
  created() {

  },

  mounted() {
    document.title = this.title;
  },
}
</script>
<style scoped>
.home{}
</style>
